<template>
  <div class="search_item m-r-10">
    <label class="label"><slot /></label>
    <el-popover v-model="visible" placement="bottom" width="260" trigger="click" @show="show">
      <div>
        <h4>常用时间选择</h4>
        <div>
          <el-row :gutter="0">
            <el-col v-for="(item, i) in dataTypeArr" :key="i" :span="6" class="m-b-10">
              <el-button @click="dataTypeChange(item)" :type="item === dataType ? 'primary' : ''" size="mini" round>
                {{ item }}
              </el-button>
            </el-col>
          </el-row>
        </div>
        <h4>月份筛选</h4>
        <el-date-picker
          v-model="month"
          size="mini"
          type="month"
          placeholder="请选择月份"
          format="yyyy-MM"
          value-format="yyyy-MM"
          :clearable="false"
          @change="changeMonth"
        >
        </el-date-picker>
        <h4>其他时间选择</h4>
        <el-date-picker
          v-model="dateRange"
          size="mini"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          :clearable="false"
          @change="changeDateRange"
        >
        </el-date-picker>
        <div class="flex flex-center m-t-20">
          <el-button size="mini" class="flex1" @click="reset">重置</el-button>
          <el-button type="primary" size="mini" class="flex1" @click="confirm">确认</el-button>
        </div>
      </div>
      <el-input slot="reference" v-model="dateValue" readonly size="mini" placeholder="请选择时间">
        <div slot="suffix" class="flex flex-center height-full m-r-5">
          <i class="el-icon-arrow-down font-size-14 color-9"> </i>
        </div>
      </el-input>
    </el-popover>
  </div>
</template>

<script>
export default {
  name: "DateSelect",
  // props: {
  //   timeType: {
  //     type: String,
  //     default: "本月"
  //   },
  //   size: {
  //     type: String,
  //     default: "mini"
  //   }
  // },
  data() {
    return {
      visible: false,
      dataType: "本月",
      month: "",
      dateRange: [],
      dataTypeArr: ["今天", "昨天", "本周", "上周", "本月", "上月", "今年", "上年"],
      confirmData: {}
    };
  },
  computed: {
    dateValue() {
      const { dataType, month, dateRange } = this.confirmData;
      return dataType || month || (dateRange && dateRange.join(" - ")) || "";
    }
  },
  mounted() {
    this.dataTypeChange(this.dataType);
    this.setConfirmData();
  },
  methods: {
    dataTypeChange(type) {
      if (!type) return;
      this.dataType = type;
      const arr = window.tool.getDateRangeByName(type);
      this.dateRange = arr;
      if (["本月", "上月"].includes(type)) {
        this.month = arr[0].substring(0, 7);
      } else {
        this.month = "";
      }
    },
    changeMonth() {
      // 本月
      const currentMonthRange = window.tool.getThisMonthDateRange();
      // 上月
      const lastMonthRange = window.tool.getLastMonthDateRange();
      // 本月
      const currentMonth = currentMonthRange[0].substring(0, 7);
      // 上月
      const lastMonth = lastMonthRange[0].substring(0, 7);
      // 内容切换
      if (this.month === currentMonth) {
        this.dateRange = currentMonthRange;
        this.dataType = "本月";
      } else if (this.month === lastMonth) {
        this.dateRange = lastMonthRange;
        this.dataType = "上月";
      } else {
        // const year = this.month.split("-")[0];
        // const month = this.month.split("-")[1];
        // const days = window.tool.getDaysInMonth(year, month);
        // this.dateRange = [`${this.month}-01`, `${this.month}-${days}`];
        this.dateRange = [];
        this.dataType = "";
      }
    },
    changeDateRange() {
      if (this.dateRange.length === 2) {
        this.dataType = window.tool.getDateNameByRange(this.dateRange[0], this.dateRange[1]);
        this.month = "";
        this.dataTypeChange(this.dataType);
      } else {
        this.dataType = "";
        this.month = "";
      }
    },
    reset() {
      this.dataTypeChange("本月");
    },
    confirm() {
      const data = {
        startDate: this.dateRange.length === 2 ? this.dateRange[0] : "",
        endDate: this.dateRange.length === 2 ? this.dateRange[1] : ""
      };
      if (!data.startDate && !data.endDate && this.month) {
        const target = window.tool.getThisMonthDateRange(this.month);
        data.startDate = target[0];
        data.endDate = target[1];
      }
      this.setConfirmData();
      this.visible = false;
      this.$emit("change", data);
    },
    show() {
      for (const key in this.confirmData) {
        this[key] = this.confirmData[key];
      }
    },
    setConfirmData() {
      const confirmData = {
        dateRange: this.dateRange,
        month: this.month,
        dataType: this.dataType
      };
      this.confirmData = JSON.parse(JSON.stringify(confirmData));
    }
  }
};
</script>

<style lang="less" scoped>
.search_item {
  display: flex;
  align-items: center;
  .label {
    width: 70px;
    margin-right: 4px;
    display: inline-block;
    text-align: right;
  }
  /deep/.el-input {
    width: 180px;
    // .el-input__inner {
    //   padding: 0 10px;
    // }
  }
}
/deep/ .el-date-editor.el-input,
/deep/ .el-date-editor--datetimerange.el-input__inner {
  width: 260px;
}
</style>
