<template>
  <div class="client-page  flex-column">
    <!-- <div class="title-box border-bottom-1">
      订单列表
    </div> -->
    <el-tabs v-model="activeName" type="card" class="flex1 flex-column overflow-hidden">
      <el-tab-pane v-for="tab in tabs" :label="tab.name" :name="tab.id" :key="tab.id">
        <OrderItem ref="OrderItem" :tabItem="tab" v-if="activeName == tab.id" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import OrderItem from "./components/OrderItem.vue";
import { getOrdersCounts } from "@/api/order";

export default {
  name: "orderList",
  components: {
    OrderItem
  },
  data() {
    return {
      keyScope: "OrderList",
      activeName: "0",
      countObj: {}
      /** 状态，多个用逗号分隔 1 : 待确认 2 : 待发货 3 : 待收货
       * 4 : 已完成 5 : 买家取消 6 : 卖家取消
       * 7 : 系统取消 9 : 待付款 10: 待服务 11: 待提交 */
      // tabs: [
      //   { id: "0", name: "全部订单" },
      //   { id: "1", name: "待确认" },
      //   { id: "9", name: "待付款" },
      //   { id: "2", name: "待发货" },
      //   { id: "3", name: "待收货" },
      //   { id: "10", name: "待服务" },
      //   { id: "4", name: "已完成" }
      // ]
    };
  },
  mounted() {
    this.getOrdersCountsFn();
    this.$bus.$on("refreshOrdersCount", () => {
      this.getOrdersCountsFn();
    });
  },
  destroyed() {
    this.$bus.$off("refreshOrdersCount");
  },
  activated() {
    setTimeout(() => {
      this.$hotkeys("*", this.keyScope, this.handleKeyCode);
      this.$hotkeys.setScope(this.keyScope);
    }, 500);
  },
  deactivated() {
    this.$hotkeys.unbind("*", this.keyScope);
  },
  computed: {
    tabs() {
      return [
        { id: "0", name: `全部订单(${this.countObj.total})` },
        { id: "1", name: `待确认(${this.countObj.toConfirm})` },
        { id: "9", name: `待付款(${this.countObj.toPay})` },
        { id: "2", name: `待发货(${this.countObj.toDeliver})` },
        { id: "3", name: `待收货(${this.countObj.toReceive})` },
        // { id: "10", name: `待服务(${this.countObj.toService})` },
        { id: "4", name: `已完成(${this.countObj.completed})` }
      ];
    }
  },
  methods: {
    getOrdersCountsFn() {
      getOrdersCounts()
        .then(res => {
          this.countObj = res || {};
        })
        .catch(err => {
          this.$message.error(err?.message);
        });
    },
    handleKeyCode(e) {
      switch (e.code) {
        case "F3":
          e.preventDefault();
          e.stopPropagation();
          if (this.$refs.OrderItem[0].canDeleteBatch) {
            this.$refs.OrderItem[0].deleteBatchFn();
          }
          break;
        case "F5":
          e.preventDefault();
          e.stopPropagation();
          this.$refs.OrderItem[0].queryFn();
          break;
        case "F6":
          e.preventDefault();
          e.stopPropagation();
          this.$refs.OrderItem[0].resetFn();
          break;
        case "F9":
          e.preventDefault();
          e.stopPropagation();
          if (this.$refs.OrderItem[0].canPayBatch) {
            this.$refs.OrderItem[0].toPayBatchFn();
          }
          break;
        case "F10":
          e.preventDefault();
          e.stopPropagation();
          if (this.$refs.OrderItem[0].canConfirmReceiptBatch) {
            this.$refs.OrderItem[0].confirmReceiptBatchFn();
          }
          break;
        case "Escape":
          e.preventDefault();
          e.stopPropagation();
          if (this.$refs.OrderItem[0].canCancelBatch) {
            this.$refs.OrderItem[0].cancelBatchFn();
          }
          break;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.title-box {
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  background: #eeeeee;
}
/deep/.el-tabs__header {
  margin: 0 0 8px;
}
/deep/.el-tabs__content {
  flex: 1;
  .el-tab-pane {
    height: 100%;
  }
}
</style>
